import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import SimpleLinkMd from '../../customLinks/simpleLinkMd'

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { isTextJustify: boolean }) => ({
    textAlign: props.isTextJustify ? 'justify' : 'center',
  }),
  customStrong: {
    fontWeight: 'bold',
    textShadow: `1px 1px ${theme.palette.primary.dark}`,
  },
  simpleStrong: {
    fontWeight: 'bold',
  },
}))

interface TextMarkdownProps {
  text: string
  isCustomBold?: boolean
  allowDangerousHtml?: boolean
  isTextJustify?: boolean
}

const TextMarkdown: FC<TextMarkdownProps> = props => {
  const { text, isCustomBold } = props
  const allowDangerousHtml =
    props.allowDangerousHtml !== undefined ? props.allowDangerousHtml : true
  const isTextJustify =
    props.isTextJustify !== undefined ? props.isTextJustify : true

  const classes = useStyles({ isTextJustify })

  return (
    <span className={classes.root}>
      <ReactMarkdown
        children={text}
        allowDangerousHtml={allowDangerousHtml}
        renderers={{
          link: props => {
            return <SimpleLinkMd href={props.href} children={props.children} />
          },
          strong: props => {
            return (
              <span
                className={
                  isCustomBold ? classes.customStrong : classes.simpleStrong
                }
              >
                {props.children}
              </span>
            )
          },
        }}
      />
    </span>
  )
}

export default TextMarkdown
