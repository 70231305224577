import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'gatsby'
import React, { FC, ReactNode } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}))

interface SimpleLinkMdProps {
  href: string
  children: ReactNode
}

const SimpleLinkMd: FC<SimpleLinkMdProps> = props => {
  const { href, children } = props
  const classes = useStyles()

  return (
    <Link to={href} className={classes.link}>
      {children}
    </Link>
  )
}

export default SimpleLinkMd
